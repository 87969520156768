import React, { useState } from 'react'
import Box from './styled/box'
import styled from 'styled-components'
import { device } from './device'

const BoxWrapper = styled.a`
  &:hover {
    cursor: pointer;
  }
`

const VehicleListStyled = styled.div`
  ${Box} {
    margin-bottom: 1rem;
  }

  @media ${device.laptopL} {
    display: block;
  }
`

const CheckBox = styled.label`
  display: block;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: #f7f7f7;
`

const SelectedVehicleBox = styled(Box)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
`

const VehicleBox = styled(Box)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const VehicleList = ({ vehicles, selected, onClicked }) => {
  const [showActiveOnly, setShowActiveOnly] = useState(false)

  const onActiveVehiclesChange = (e) => setShowActiveOnly(e.target.checked)
  const activeVehicles = vehicles ? vehicles.filter((v) => v.settings.active) : []
  const data = showActiveOnly ? activeVehicles : vehicles

  return (
    <VehicleListStyled>
      <CheckBox>
        <input type="checkbox" onChange={onActiveVehiclesChange} />
        Vis kun aktive biler
      </CheckBox>
      {data.map((vehicle, i) => {
        const isSelected = selected && vehicle.vehicle.regno === selected.vehicle.regno

        return (
          <BoxWrapper key={`vehicle-${i}`} onClick={() => onClicked(vehicle.vehicle.regno)}>
            {isSelected ? (
              <SelectedVehicleBox>
                <strong>{vehicle.vehicle.regno}</strong>
                <br />
                {vehicle.data && (
                  <small>
                    {vehicle.data.merkeNavn} {vehicle.data.modellbetegnelse} {vehicle.data.regAAr}
                  </small>
                )}
              </SelectedVehicleBox>
            ) : (
              <VehicleBox>
                <strong>{vehicle.vehicle.regno}</strong>
                <br />
                {vehicle.data && (
                  <small>
                    {vehicle.data.merkeNavn} {vehicle.data.modellbetegnelse} {vehicle.data.regAAr}
                  </small>
                )}
              </VehicleBox>
            )}
          </BoxWrapper>
        )
      })}
    </VehicleListStyled>
  )
}

export default VehicleList
