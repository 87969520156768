import React from 'react'

const NoDataFound = ({ username }) => {
  return (
    <React.Fragment>
      <h2>Ingen data</h2>
      <p>Vi har ingen kjøretøy registrert på telefonnummer {username}.</p>
    </React.Fragment>
  )
}

export default NoDataFound
