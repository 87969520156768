import styled from 'styled-components'

export const TabsStyled = styled.ul`
  margin: 1rem 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;
`

export const TabItem = styled.li`
  flex: 1;
  padding: 1rem;
  border-top: 4px solid transparent;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid transparent;
  transition: all 0.25s ease-out;

  &.selected {
    color: var(--primary-color);
    border-top-color: var(--primary-color);
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom-color: transparent;
  }

  &:hover {
    cursor: pointer;
  }
`
