import React, { useState } from 'react'
import Box from '../styled/box'
import { Column1, Column3, GridWithGap } from '../styled/grid'
import styled from 'styled-components'
import Date from '../date'
import { SecondaryButtonSmall } from '../styled/buttons'

const HistoryItem = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dddddd;
`

const HistoryOrderLines = ({ text }) => {
  const [showOrderLines, setShowOrderLines] = useState(false)

  const formattedText = text
    ? text.replace('["', '').replace('"]', '').split('","').join('<br />')
    : ''

  return (
    <React.Fragment>
      {showOrderLines && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
      <SecondaryButtonSmall
        onClick={(e) => {
          e.preventDefault()
          setShowOrderLines(!showOrderLines)
        }}
      >
        {showOrderLines ? 'Skjul ordrelinjer' : 'Vis ordrelinjer'}
      </SecondaryButtonSmall>
    </React.Fragment>
  )
}

const descending = (a, b) => (a.invoice_date < b.invoice_date ? 1 : -1)

const History = ({ history }) => {
  history = history.reverse()

  return (
    <Box style={{ borderTop: 'none' }}>
      {history.length > 0 &&
        history.sort(descending).map((h, i) => (
          <HistoryItem key={`history-${i}`}>
            <GridWithGap>
              <Column1 style={{ padding: '0.3rem 0 0.3rem 0' }}>Faktura dato</Column1>
              <Column3 style={{ padding: '0.3rem 0 0.3rem 0' }}>
                <Date date={h.invoice_date} />
              </Column3>
            </GridWithGap>
            <GridWithGap>
              <Column1 style={{ padding: '0.3rem 0 0.3rem 0' }}>Faktura nr.</Column1>
              <Column3 style={{ padding: '0.3rem 0 0.3rem 0' }}>{h.invoice_no}</Column3>
            </GridWithGap>
            <GridWithGap>
              <Column1 style={{ padding: '0.3rem 0 0.3rem 0' }}>Ordre type</Column1>
              <Column3 style={{ padding: '0.3rem 0 0.3rem 0' }}>{h.order_type}</Column3>
            </GridWithGap>
            <GridWithGap>
              <Column1 style={{ padding: '0.3rem 0 0.3rem 0' }}>Kilometerstand</Column1>
              <Column3 style={{ padding: '0.3rem 0 0.3rem 0' }}>{h.mileage}</Column3>
            </GridWithGap>
            <GridWithGap>
              <Column1 style={{ padding: '0.3rem 0 0.3rem 0' }}>Ordrelinjer</Column1>
              <Column3 style={{ padding: '0.3rem 0 0.3rem 0' }}>
                <HistoryOrderLines text={h.text} />
              </Column3>
            </GridWithGap>
            <GridWithGap>
              <Column1 style={{ padding: '0.3rem 0 0.3rem 0' }}>Verksted</Column1>
              <Column3 style={{ padding: '0.3rem 0 0.3rem 0' }}>{h.workshop_name}</Column3>
            </GridWithGap>
          </HistoryItem>
        ))}
    </Box>
  )
}

export default History
