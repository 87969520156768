import { Column1, GridWithGap } from '../styled/grid'
import React from 'react'
import DateBox from '../datebox'

export const DateBoxes = ({ selectedVehicle }) => {
  return (
    <React.Fragment>
      {selectedVehicle && (
        <GridWithGap>
          <Column1>
            <DateBox title="Neste EU-kontroll" date={selectedVehicle?.data?.nestePKK ?? '-'} />
          </Column1>
          <Column1>
            <DateBox title="Neste time">date={selectedVehicle.vehicle.next_work_date}</DateBox>
          </Column1>
          <Column1>
            <DateBox
              title="Neste beregnet service"
              date={selectedVehicle.vehicle.next_service_date}
            />
          </Column1>
          <Column1>
            <DateBox
              title="Mobilitetsgaranti utløper 1 mnd. etter denne dato"
              date={selectedVehicle.vehicle.mob_expiry_date}
            />
          </Column1>
        </GridWithGap>
      )}
    </React.Fragment>
  )
}
