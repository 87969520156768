import React, { useEffect, useState } from 'react'
import { Section } from '../components/styled/section'
import VehicleList from '../components/vehicleList'
import * as jwtDecode from 'jwt-decode'
import { ErrorMessage } from '../components/styled/errorMessage'
import { useGetToken } from '../hooks/use-get-token'
import MyPageLayout from '../layouts/my-page-layout'
import History from '../components/my-page/history'
import Xtra from '../components/my-page/xtra'
import { VehicleHeader } from '../components/my-page/vehicleHeader'
import { DateBoxes } from '../components/my-page/date-boxes'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { Column1, Column3, GridWithGap } from '../components/styled/grid'
import NoDataFound from '../components/my-page/no-data-found'
import MyPageTabs from '../components/my-page/my-page-tabs'
import styled from 'styled-components'
import VehicleSettings from '../components/my-page/vehicle-settings'
import { authenticatedRequest } from '../services/data.service'

const Title = styled.h1`
  margin: 0 0 1rem 0;
  font-size: 2rem;
  font-weight: 700;
`
const HolderContetnt = styled.div`
  margin-top: 1rem;

  @media (max-width: 1433px) {
    margin-top: -7rem;
  }
`

const MyPage = (callback, deps) => {
  const token = useGetToken()
  const location = useLocation()
  const { username: phone } = token ? jwtDecode(token) : { username: null }
  const search = queryString.parse(location.search)
  const [loading, setLoading] = useState(false)
  const [regno, setRegno] = useState(search.regno)
  const [selectedVehicle, setSelectedVehicle] = useState(undefined)
  const [vehicles, setVehicles] = useState([])
  const [history, setHistory] = useState([])
  const [xtra, setXtra] = useState(undefined)
  const [error, setError] = useState('')
  const [loadingData, setLoadingData] = useState(false)
  const [tab, setTab] = useState(0)

  const hasVehicles = vehicles && vehicles.length > 0

  const fetchData = (reg, ph) => {
    setLoadingData(true)

    authenticatedRequest(`${process.env.API_URL}history/${reg}/${ph}`)
      .then(({ history, xtra }) => {
        setHistory(history)
        setXtra(xtra)
        setLoadingData(false)
      })
      .catch((e) => {
        setLoadingData(false)
        setError(e)
      })
  }

  useEffect(() => {
    setLoading(true)

    authenticatedRequest(`${process.env.API_URL}vehicle/phone/${phone}`)
      .then((vehicles) => {
        setVehicles(vehicles)
        setLoading(false)

        const index = vehicles.findIndex(({ vehicle }) => vehicle.regno === regno)

        if (index !== -1) {
          setSelectedVehicle(vehicles[index])
          setRegno(vehicles[index].vehicle.regno)

          return fetchData(regno, phone)
        }
      })
      .catch((e) => {
        setError(e)
        setLoading(false)
      })
  }, [phone, regno])

  return (
    <MyPageLayout title="Bileiers side">
      <HolderContetnt>
        <Section>
          {!loading && !hasVehicles && <NoDataFound username={phone} />}

          <GridWithGap>
            <Column3>
              {loading ? (
                <p>Henter kjøretøy og historikk...</p>
              ) : (
                <React.Fragment>
                  {!selectedVehicle ? (
                    <React.Fragment>
                      <Title>Ingen historikk</Title>
                      <p>Fant ingen historikk registrert på deg med registreringsnummer {regno}</p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <VehicleHeader selectedVehicle={selectedVehicle} />
                      <DateBoxes selectedVehicle={selectedVehicle} />

                      {loadingData && <p>Henter historikk...</p>}

                      {!loadingData && (
                        <MyPageTabs onTabSelected={setTab}>
                          {tab === 0 && <History history={history} />}

                          {tab === 1 && <Xtra xtra={xtra} />}

                          {tab === 2 && (
                            <VehicleSettings
                              regno={selectedVehicle.vehicle.regno}
                              phone={phone}
                              settings={selectedVehicle.settings}
                            />
                          )}
                        </MyPageTabs>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Column3>

            <Column1>
              <h4 style={{ margin: '0 0 1rem 0' }}>Dine biler</h4>

              {loading && !hasVehicles ? (
                <p>Henter biler...</p>
              ) : error ? (
                <ErrorMessage>Det oppstod en feil.</ErrorMessage>
              ) : (
                <VehicleList
                  loading={loading}
                  error={error}
                  vehicles={vehicles}
                  selected={selectedVehicle}
                  onClicked={(regno) => {
                    setTab(0)
                    setRegno(regno)
                  }}
                />
              )}
            </Column1>
          </GridWithGap>
        </Section>
      </HolderContetnt>
    </MyPageLayout>
  )
}

export default MyPage
