import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  margin: 0 0 1rem 0;
  font-size: 2rem;
  font-weight: 700;
`

const SubHeader = styled.h2`
  margin: 0 0 2rem 0;
  font-size: 1rem;
  font-weight: 700;
`

export const VehicleHeader = ({ selectedVehicle }) => {
  return (
    <React.Fragment>
      {selectedVehicle && <Title>{selectedVehicle.vehicle.regno}</Title>}
      {selectedVehicle && selectedVehicle.data && (
        <SubHeader>
          {selectedVehicle.data.merkeNavn} {selectedVehicle.data.modellbetegnelse}{' '}
          {selectedVehicle.data.regAAr}
        </SubHeader>
      )}
    </React.Fragment>
  )
}
