import React from 'react'
import Box from '../components/styled/box'
import Date from './date'

const DateBox = ({ title, date }) => {
  return (
    <Box style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
      <strong>{title}</strong>
      <br />
      {date && <Date date={date} />}
      {!date && 'Ikke oppgitt'}
    </Box>
  )
}

export default DateBox
