import React, { useState } from 'react'
import styled from 'styled-components'
import { FormGroup, Label, TextArea } from '../styled/forms'
import { DangerButtonSmall, PrimaryButtonSmall } from '../styled/buttons'
import { ErrorMessage } from '../styled/errorMessage'

const Settings = styled.div`
  margin-top: 2rem;
`

const Row = styled.div`
  margin-top: 1rem;
`

const VehicleSettings = ({ settings, regno, phone }) => {
  const [status, setStatus] = useState('idle')
  const [description, setDescription] = useState(settings.description)
  const [active, setActive] = useState(settings.active)

  const updateDescription = () => updateSettings({ ...settings, description })
  const updateActiveStatus = (active) => updateSettings({ ...settings, active })

  const updateSettings = (updatedSettings) => {
    setStatus('pending')

    delete updatedSettings.regno

    fetch(`${process.env.API_URL}vehicle/${regno}/settings`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...updatedSettings,
        phone,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          setStatus('idle')
          const data = await response.json()
          setDescription(data.description)
          setActive(data.active)
        } else {
          setStatus('failure')
        }
      })
      .catch((error) => {
        console.error(error)
        setStatus('failure')
      })
  }

  return (
    <Settings>
      {status === 'failure' && <ErrorMessage>Det oppstod dessverre en feil</ErrorMessage>}
      <FormGroup>
        <Label>Status</Label>
        <p>Denne bilen er {active ? 'aktiv' : 'inaktiv'}.</p>
        {active && (
          <DangerButtonSmall onClick={() => updateActiveStatus(false)}>Deaktiver</DangerButtonSmall>
        )}
        {!active && (
          <PrimaryButtonSmall onClick={() => updateActiveStatus(true)}>Aktivér</PrimaryButtonSmall>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Beskrivelse</Label>
        <TextArea value={description} onChange={({ target }) => setDescription(target.value)} />
        <small>Eks. Petter kjører denne bilen.</small>
        <Row>
          <PrimaryButtonSmall onClick={() => updateDescription()}>Oppdater</PrimaryButtonSmall>
        </Row>
      </FormGroup>
      {status === 'pending' && <p>Oppdaterer innstillinger...</p>}
    </Settings>
  )
}

export default VehicleSettings
