import React, { useState } from 'react'
import { TabItem, TabsStyled } from '../styled/tabs-styled'

const MyPageTabs = ({ onTabSelected, children }) => {
  const [tab, setTab] = useState(0)
  const tabItems = ['Historikk', 'Xtrasjekk skjema', 'Innstillinger']

  const selectTab = (index) => {
    setTab(index)
    onTabSelected(index)
  }

  return (
    <React.Fragment>
      <TabsStyled>
        {tabItems.map((tabItem, index) => (
          <TabItem
            key={`tab-${index}`}
            className={tab === index ? 'selected' : ''}
            onClick={() => selectTab(index)}
          >
            {tabItem}
          </TabItem>
        ))}
      </TabsStyled>
      {children}
    </React.Fragment>
  )
}

export default MyPageTabs
